/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_LIGHTER_BLUE } from './colors';
import FlowClubLogo from './images/flowclublogo.svg';

import { Timer } from './Timer';

const App = () => {
  return (
    <div css={css`
      min-height: 100vh;
      width: 100vw;
      
      background: ${FC_LIGHTER_BLUE};
      font-size: 16px;
      line-height: 24px;
      font-family: "Red Hat Display", sans-serif;
      display: flex;
      text-align: center;
      flex-direction: column;
      overflow: hidden;
      position: relative;
    `}>
      <div css={css`
        flex: 1;
        margin-top: 10vh;
      `}>
        <Timer />
      </div>
      <a
        href="https://www.flow.club?utm_source=phonefreehour.com"
        css={css`
          text-decoration: none;
          color: inherit;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
        `}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>Made by</div>
        <img
          src={FlowClubLogo}
          css={css`
            height: 16px;
          `}
          alt="Flow Club Logo"
          title="Flow Club Logo"
        />
      </a>
    </div>
  );
}

export default App;
